.dwcc-cookieconsent {
  position: fixed;
  z-index: 9990;
  width: 100%;
  min-width: 320px;
  display: block;
  left: 0;
  right: 0;
  border: 1px solid #eee;
  background-color: #666;
  box-sizing: border-box;
  color: #333;
  max-height: 100vh;
  overflow: visible;
  box-shadow:  rgba(0, 0, 0, 0.25) 0px 0px 10px -2px, rgba(0, 0, 0, 0.7) 0px 0px 20vh 30vh;
  animation: dwccFadeIn ease 750ms;
}

.dwcc-cookieconsent * {
  box-sizing: border-box;
  line-height: 1.3;
}
.dwcc-cookieconsent .dwcc-cmp-holder {
  display: none;
}

.dwcc-cookieconsent .hidden {
  display: none !important;
}

.dwcc-cookieconsent *:before,
.dwcc-cookieconsent *:after {
  box-sizing: inherit;
}
.dwcc-cookieconsent article:focus {
  outline: 0 none;
}
.dwcc-cookieconsent button:focus,
.dwcc-cookieconsent a:focus {
  outline: 2px dashed rgba(127,127,127, 0.5);
  outline-color: invert;
  outline-offset: 5px;
}

.dwcc-cookieconsent .dwcc-clear:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

.dwcc-cookieconsent .dwcc-inner {
  padding: 0px 15px 5px 15px;
  margin: auto;
  max-width: 1280px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
}
.dwcc-cookieconsent .dwcc-type-box:focus {
  outline: 0 none;
  box-shadow: none;
}

.dwcc-cookieconsent .dwcc-box_simple .dwcc-disclaimer-text {
  text-indent: 0;
}
.dwcc-cookieconsent .dwcc-disclaimer-text h1,
.dwcc-cookieconsent .dwcc-disclaimer-text p {
  margin: 10px 0;
}

.dwcc-cookieconsent .dwcc-disclaimer-text h1 {
  margin-right: 1.5em;
  font-size: 24px;
  margin-top: 20px;
  line-height: 1.2;
  text-transform: uppercase;
}
.dwcc-cookieconsent .dwcc-disclaimer-text li,
.dwcc-cookieconsent .dwcc-disclaimer-text p {
  font-size: 16px;
}
.dwcc-cookieconsent .dwcc-link {
  margin: 2px 0;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}

.dwcc-cookieconsent .dwcc-type-button {
  margin: 0.5em 0;
  margin-right: 1em;
  clear: both;
  display: inline-block;
}
.dwcc-cookieconsent .dwcc-link:hover {
  text-decoration: underline;
}

.dwcc-cookieconsent span.dwcc-type-button {
  display: inline-block;
  clear: both;
}

.dwcc-cookieconsent .dwcc-disclaimer-text {
  padding-right: 16px;
}
.dwcc-cookieconsent.dwcc-undecided .dwcc-box_simple .dwcc-disclaimer-text {
  padding-right: 0;
}

.dwcc-cookieconsent .dwcc-sticky-holder {
  position: sticky;
  top: 10px;
}

.dwcc-cookieconsent .dwcc-inner .dwcc-type-button.dwcc-component-btn_close_advanced,
.dwcc-cookieconsent .dwcc-inner .dwcc-type-button.dwcc-component-btn_close {
  position: absolute;
  top: -10px;
  right: -10px;
  margin: 0;
}
.dwcc-cookieconsent .dwcc-close {
  display: inline-block;
  display: flex;
  /* -webkit-transform: rotate(45deg); */
  /* transform: rotate(45deg); */
  font-size: 21px;
  overflow: visible;
  font-weight: 300;
  vertical-align: middle;
  text-align: center;
  line-height: 2em;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0);
  margin: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
  cursor: pointer;
}

.dwcc-cookieconsent .dwcc-close > span {
  visibility: hidden;
}
.dwcc-cookieconsent .dwcc-close::after,
.dwcc-cookieconsent .dwcc-close::before {
  position: absolute;
  display: block;
  height: 1px;
  box-sizing: content-box;
  width: 100%;
  max-width: 24px;
  background-color: #000;
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
  content: '';
  right: auto;
  box-shadow: 0px 0px 1px rgba(0,0,0,0.5);
  top: 50%;
  left: auto;
  transform-origin: center;
}
.dwcc-cookieconsent .dwcc-close::after {
  transform: rotate(45deg);
}

.dwcc-cookieconsent .dwcc-close::before {
  transform: rotate(-45deg);
}

.dwcc-cookieconsent .dwcc-close:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.dwcc-cookieconsent .dwcc-close:hover::after,
.dwcc-cookieconsent .dwcc-close:hover::before {
  background-color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.dwcc-cookieconsent .dwcc-inline-childs .dwcc-component {
  display: inline-block;
}

.dwcc-cookieconsent {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.dwcc-cookieconsent h1,
.dwcc-cookieconsent h2,
.dwcc-cookieconsent p {
  color: #000;
}

.dwcc-cookieconsent li,
.dwcc-cookieconsent p {
  color: #000;
  line-height: 1.5;
  font-size: 14px;
}

.dwcc-cookieconsent small {
  font-size: small;
}
.dwcc-cookieconsent .dwcc-buttons {
  margin-bottom: 0.25em;
  display: inline-block;
}
.dwcc-cookieconsent .dwcc-buttons .dwcc-link {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dwcc-cookieconsent .dwcc-button-secondary,
.dwcc-cookieconsent .dwcc-button {
  padding: 0.5em 1.5em;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  padding: 7px 20px;
  line-height: normal;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 15px 0 10px 0;
  margin-top: 0;
  margin-bottom: 0;
}
.dwcc-cookieconsent .dwcc-button-secondary {
  background-color: #eee;
  border-color: #eee;
  color: #000;
}

.dwcc-cookieconsent .dwcc-button-secondary:hover {
  background-color: #ccc;
  border-color: #ccc;
}

.dwcc-cookieconsent .dwcc-button:hover {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.dwcc-cookieconsent a {
  color: #333;
  font-weight: 500;
  text-decoration: none;
}

.dwcc-cookieconsent a:hover {
  color: #000;
  text-decoration: none;
}

.dwcc-cookieconsent p a:hover{
  border-bottom: 2px solid #000;
}

.dwcc-cookieconsent p a {
  color: #222;
  border-bottom: 2px solid #000;
  font-weight: 500;
}

.dwcc-cookieconsent.dwcc-placement-pushdown {
  position: absolute;
}

.dwcc-cookieconsent.dwcc-placement-topcover,
.dwcc-cookieconsent.dwcc-placement-pushdown {
  top: 0;
}

.dwcc-cookieconsent.dwcc-placement-bottomcover {
  bottom: 0;
}

.dwcc-cookieconsent .dwcc-type-category {
  max-width: 320px;
}

.dwcc-cookieconsent .dwcc-btn {
  cursor: pointer;
}

.dwcc-cookieconsent .dwcc-btn.dwcc-block-link {
  margin-bottom: 5px;
  margin-top: 5px;
  display: inline-block;
  border-bottom: 2px solid #999;
  font-size: 14px;
}

.dwcc-cookieconsent .dwcc-type-category .dwcc-cat-label {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
  font-size: 14px;
}

.dwcc-cookieconsent .dwcc-component-box_cookies {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  background-color: rgba(125,125,125, 0.95);
  z-index: 9999;
  animation: dwccFadeIn ease 750ms;
}

.dwcc-cookieconsent .dwcc-box_cookies {
  top: 0;
  max-width: 960px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 95%;
  width: 98%;
  border-radius: 10px;
  box-shadow: 0px 10px 100px rgba(0,0,0,0.5);
  position: absolute;
  margin: auto;
  background: #fff;
}

.dwcc-cookieconsent .dwcc-box_cookies ul {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.dwcc-cookieconsent .dwcc-box_cookies ul li {
  margin-bottom: 0.5em;
}

.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  height: 95%;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
  width: 100%;
}

.dwcc-cookieconsent .dwcc-inner::-webkit-scrollbar ,
.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar {
  width: 10px;
}
.dwcc-cookieconsent .dwcc-inner::-webkit-scrollbar-track,
.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.dwcc-cookieconsent .dwcc-inner::-webkit-scrollbar-thumb,
.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar-thumb {
  background-color: #222;
  border-radius: 10px;
}

.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text > h1,
.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text article {
  margin: 0px 64px 64px 2.5%;
}
.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text > p {
  margin: 0px 64px 0 2.5%;
}
.dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text > h1 {
  margin-top: 0em;
  margin-bottom: 2em;
  text-align: center;
  font-size: larger;
}
.dwcc-cookieconsent .dwcc-box_cookies .dwcc-component-btn_close_cookies {
  position: absolute;
  top: 0;
  z-index: 2;
  right: 8px;
  border-radius: 6px;
}

.dwcc-cookieconsent .dwcc-box_cookies .dwcc-table {
  overflow: auto;
  margin: 10px 0 40px 0;
}
.dwcc-cookieconsent .dwcc-box_cookies table .links a::before {
    content: '- ';
}

.dwcc-cookieconsent .dwcc-box_cookies h2,
.dwcc-cookieconsent .dwcc-box_cookies h3,
.dwcc-cookieconsent .dwcc-box_cookies h1,
.dwcc-cookieconsent .dwcc-box_cookies h4,
.dwcc-cookieconsent .dwcc-box_cookies h5 {
  line-height: 1.1;
  margin-top: 2em;
  margin-bottom: 0.25em;
}

.dwcc-cookieconsent .dwcc-box_cookies h1 { font-size: 2.25em; margin: 0.5em 0 0 0;}
.dwcc-cookieconsent .dwcc-box_cookies h2 { font-size: 2em; }
.dwcc-cookieconsent .dwcc-box_cookies h3 { font-size: 1.6em; }
.dwcc-cookieconsent .dwcc-box_cookies h4 { font-size: 1.2em; font-weight: 600; }
.dwcc-cookieconsent .dwcc-box_cookies h5 { font-size: 1.1em; font-weight: 600; }

.dwcc-cookieconsent .dwcc-box_cookies table {
  border-collapse: collapse;
  border: 0 none;
  width: 100%;
}

.dwcc-cookieconsent .dwcc-box_cookies table td,
.dwcc-cookieconsent .dwcc-box_cookies table th {
  font-size: 14px;
  border: 0 none;
  border-bottom: 1px solid #eee;
  padding: 5px 10px;
}

.dwcc-cookieconsent .dwcc-box_cookies table th {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  border-bottom: 5px solid #eee;
  text-align: left;
  padding-bottom: 0;
}

.dwcc-cookieconsent .dwcc-box_cookies table .pattern {
  width: 15%;
  word-break: break-all;
  min-width: 140px;
}


.dwcc-cookieconsent .dwcc-box_cookies table .purpose {
  width: 50%;
  min-width: 190px;
}

.dwcc-cookieconsent .dwcc-box_cookies table .expiry {
  min-width: 90px;
}

.dwcc-cookieconsent .dwcc-box_cookies table .source {
  word-break: break-all;
  hyphens: auto;
  min-width: 110px;
}

.dwcc-cookieconsent .dwcc-box_cookies table .third_party {
  min-width: 60px;
  text-align: center;
}

.dwcc-cookieconsent .dwcc-box_cookies table .domain {
  min-width: 80px;
  word-break: break-all;
}

.dwcc-cookieconsent .dwcc-box_cookies table .type {
  min-width: 55px;
}

.dwcc-cookieconsent .dwcc-box_cookies table .provider {
  width: 40%;
  min-width: 225px;
}

.dwcc-cookieconsent .dwcc-box_cookies table td.source,
.dwcc-cookieconsent .dwcc-box_cookies table td.expiry,
.dwcc-cookieconsent .dwcc-box_cookies table td.domain,
.dwcc-cookieconsent .dwcc-box_cookies table td.type {
  font-size: 13px;
}

.dwcc-cookieconsent .dwcc-box_cookies table .links {
  width: 20%;
  min-width: 200px;
  text-align: center;
}
.dwcc-cookieconsent .dwcc-box_cookies table .links a {
  margin: 5px 0px;
  display: inline-block;
  font-weight: 600;
}
.dwcc-cookieconsent .dwcc-box_cookies table td.links a {
  font-size: 14px;
}
.dwcc-cookieconsent .dwcc-box_cookies table .links a::before {
  content: '• ';
}

.dwcc-cookieconsent .dwcc-box_cookies dl {
  padding: 0.5em 1.5em 0.25em 1.5em;
  border-left: 5px solid #eee;
  margin: 1em 0 2em 0;
}
.dwcc-cookieconsent .dwcc-box_cookies dt {
  font-size: small;
  font-weight: bold;
}
.dwcc-cookieconsent .dwcc-box_cookies dd {
  margin: 0.5em 0em 1.5em 2em;
  font-size: small;
}

.dwcc-cookieconsent .dwcc-links {
  display: inline-block;
  margin-left: 2em;
}
.dwcc-cookieconsent .dwcc-inner .dwcc-links .dwcc-type-button {
  margin-right: 1.5em;
}

.dwcc-cookieconsent ul {
  padding: 0 0 0 1.5em;
  margin: 0;
}

.dwcc-cookieconsent .dwcc-icon-close,
.dwcc-cookieconsent .dwcc-icon-info {
  width: 18px;
  height: 18px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
}
.dwcc-cookieconsent .dwcc-icon-info {
  /* display: inline-block; */
  color: #000;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3e%3cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3e%3cpath d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/%3e%3c/svg%3e");
  top: 4px;
  margin-left: 2px;
  cursor: help;
}
.dwcc-cookieconsent .dwcc-icon-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3e%3cpath d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/%3e%3c/svg%3e");
  display: none;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 3;
}
.dwcc-cookieconsent .dwcc-icon-info.dwcc-icon-info-active + [role="tooltip"] .dwcc-icon-close {
  display: block;
}
.dwcc-cookieconsent [role=tooltip] {
  text-transform: none;
  display: none;
  position: absolute;
  top: 2.8em;
  font-size: 13px;
  left: 0;
  padding: 0.5em 1em;
  background-color: #fff;
  border-radius: 5px;
  z-index: 4;
  box-shadow: 0px -5px 30px rgb(0, 0, 0, 0.25);
}
.dwcc-cookieconsent [role=tooltip]:after { /* Add a downward arrow to style the tooltip */
  position: absolute;
  left: 30px;
  top: -15px;
  content: '';
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 15px solid #fff;
  border-top: none;
}
.dwcc-cookieconsent [role=tooltip] ul  {
  padding-bottom: 1em;
}
.dwcc-cookieconsent [role=tooltip] h2  {
  font-size: 125%;
  margin: 0.5em 32px 0.5em 0;
}
.dwcc-cookieconsent  [role=tooltip] li,
.dwcc-cookieconsent  [role=tooltip] p {
  font-size: 1em;
  font-weight: normal;
}

.dwcc-cookieconsent .dwcc-icon-info.dwcc-icon-info-active + [role="tooltip"],
.dwcc-cookieconsent .dwcc-icon-info:hover + [role="tooltip"],
.dwcc-cookieconsent .dwcc-icon-info:focus + [role="tooltip"] {
  display: block
}

@media screen and (max-width: 1020px) {

  .dwcc-cookieconsent .dwcc-inner .dwcc-links .dwcc-type-button {
    margin-right: 2em;
  }

  .dwcc-cookieconsent .dwcc-type-button {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    margin-right: 0.25em;
  }

}

@media screen and (max-width: 800px) {
  .dwcc-cookieconsent.dwcc-reject-on .dwcc-links {
    display: block;
    margin-left: 0.5em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }

  .dwcc-cookieconsent .dwcc-links {
    margin-left: 0.5em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
  .dwcc-cookieconsent .dwcc-buttons.dwcc-reject-on {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .dwcc-cookieconsent .dwcc-icon-info {
    display: inline-block;
  }
  .dwcc-cookieconsent .dwcc-box_cookies .dwcc-cookies-text {
    font-size: 2.75vw;
  }
  .dwcc-cookieconsent .dwcc-inner .dwcc-disclaimer-text h1 {
    font-size: 18px;
  }

  .dwcc-cookieconsent .dwcc-disclaimer-text li,
  .dwcc-cookieconsent .dwcc-disclaimer-text p {
    font-size: 14px;
  }
  .dwcc-cookieconsent.dwcc-theme-bmw .dwcc-link {
    font-size: 13px;
  }


  .dwcc-cookieconsent .dwcc-type-box .dwcc-type-button,
  .dwcc-cookieconsent .dwcc-type-button.dwcc-component-btn_advanced,
  .dwcc-cookieconsent .dwcc-type-button.dwcc-component-btn_cookies {
    margin-left: 0;
  }

  .dwcc-cookieconsent .dwcc-rangeslider-categories .dwcc-rangeslider-category .dwcc-rangeslider-label {
    font-size: 14px;
    text-transform: none;
  }
  .dwcc-cookieconsent .dwcc-btn.dwcc-block-link {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
  }



  .dwcc-cookieconsent .dwcc-rangeslider-details {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .dwcc-cookieconsent.dwcc-reject-on.dwcc-advaslink-off .dwcc-component-btn_accept {
    width: 100%;
  }

  .dwcc-cookieconsent.dwcc-reject-on.dwcc-advaslink-off  .dwcc-button.dwcc-btn_accept{
    font-size: 16px;
    margin-top: 0.25em;
  }
}


@media screen and (max-width: 440px) {
  .dwcc-cookieconsent.dwcc-reject-on .dwcc-component-btn_acceptall_advanced,
  .dwcc-cookieconsent.dwcc-reject-on .dwcc-component-btn_accept {
    width: 100%;
  }
  .dwcc-cookieconsent.dwcc-reject-on .dwcc-button.dwcc-btn_acceptall_advanced {
    margin-top: 0.25em;
  }
  .dwcc-cookieconsent.dwcc-advaslink-off  .dwcc-button.dwcc-btn_accept,
  .dwcc-cookieconsent.dwcc-reject-on  .dwcc-button.dwcc-btn_accept{
    font-size: 16px;
    margin-top: 0.25em;
  }
  .dwcc-cookieconsent.dwcc-reject-on .dwcc-box_advanced .dwcc-buttons .dwcc-type-button {
    width: 100%;
  }
}

@keyframes dwccFadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
