/* custom theme */
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-disclaimer-text li,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-disclaimer-text p {
  font-size: smaller;
}
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-rangeslider-category p {
  font-size: small;
}

.dwcc-cookieconsent.dwcc-theme-zeiss {
  /* font-family: "Frutiger Regular","Helvetica Neue",Helvetica,Arial,sans-serif; */
  /* font-family: "Frutiger Next","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif; */
  font-family: inherit;
  animation: dwccFadeIn ease 1s;
  background-color: #fff;
  border-color: #fff;
  color: #4d4f53;
}
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-rangeslider-label,
.dwcc-cookieconsent.dwcc-theme-zeiss li,
.dwcc-cookieconsent.dwcc-theme-zeiss h1,
.dwcc-cookieconsent.dwcc-theme-zeiss h2,
.dwcc-cookieconsent.dwcc-theme-zeiss h3,
.dwcc-cookieconsent.dwcc-theme-zeiss h4,
.dwcc-cookieconsent.dwcc-theme-zeiss h5,
.dwcc-cookieconsent.dwcc-theme-zeiss p {
  color: #000;
}


.dwcc-cookieconsent.dwcc-theme-zeiss [role=tooltip] {
  color: #000;
}


.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button-secondary,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button {
  background-color: #0072ef;
  color: #fff;
  border: 1px solid #0072ef;
  border-radius: 3px;
  padding: 0.8em 1.5em;
  line-height: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 15px;
  text-transform: none;
  transition: all 0.3s ease;
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button-secondary {
  background: #fff;
  color: #0072ef;
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button-secondary:hover,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button-secondary:focus,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button:focus,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button:hover {
  background-color: #0052AA;
  color: #FFFFFF;
}
.dwcc-cookieconsent.dwcc-theme-zeiss a {
  color:#0072ef;
  font-weight: 500;
  border-bottom: 1px solid #0072ef;
}

.dwcc-cookieconsent.dwcc-theme-zeiss a:focus,
.dwcc-cookieconsent.dwcc-theme-zeiss a:hover {
  color: #0052AA;
  text-decoration: none;
  border-bottom: 1px solid #0052AA;
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies  {
  color: inherit;
}

.dwcc-cookieconsent.dwcc-theme-zeiss  .dwcc-box_cookies table .links a {
  font-weight: 500;
}
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies table .links a::before {
    content: '- ';
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-link {
  font-size: 14px;
}

/* .dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-link,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-btn.dwcc-block-link {
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-link:hover,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-btn.dwcc-block-link:hover {
}
 */

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies li a:hover,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies table a:hover,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies p a:hover {

}

.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider::-webkit-slider-thumb {
  background: #0072ef;
  border: 1px solid #0072ef;
  box-shadow: 1px 1px 1px rgba(0,0,0, 0.25);
}

.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider::-moz-range-thumb {
  background: #0072ef;
  border: 1px solid #0072ef;
  box-shadow: 1px 1px 1px rgba(0,0,0, 0.25);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider::-ms-thumb {
  background: #0072ef;
  border: 1px solid #0072ef;
  box-shadow: 1px 1px 1px rgba(0,0,0, 0.25);
}



.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:focus::-webkit-slider-thumb {
  background: #0052AA;
  border-color: #0052AA;
}

.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:focus::-moz-range-thumb {
  background: #0052AA;
  border-color: #0052AA;
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangesli:focusder::-ms-thumb {
  background: #0052AA;
  border-color: #0052AA;
}




.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-inner::-webkit-scrollbar-track,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar-track {
  border-radius: 6px;
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #0072ef;
}
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar {
  width: 14px;
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-inner::-webkit-scrollbar-thumb {
  background-color: #0072ef;
}
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-inner::-webkit-scrollbar {
  width: 8px;
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-rangeslider-label {
  font-size: 14px;
}

.dwcc-cookieconsent.dwcc-theme-zeiss p a {
  border-bottom: 0 none;
}
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-disclaimer-text h1 {
  text-transform: none;
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider::-webkit-slider-runnable-track {
  background: rgba(0,0,0, 0.25);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:hover::-webkit-slider-runnable-track,
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:focus::-webkit-slider-runnable-track {
  background: rgba(0,0,0, 0.35);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider::-moz-range-track {
  background: rgba(0,0,0, 0.25);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:hover::-moz-range-track,
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:focus::-moz-range-track {
  background: rgba(0,0,0, 0.35);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider::-ms-fill-lower {
  background: rgba(0,0,0, 0.25);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider::-ms-fill-upper {
  background: rgba(0,0,0, 0.25);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:hover::-ms-fill-lower,
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:focus::-ms-fill-lower {
  background: rgba(0,0,0, 0.35);
}
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:hover::-ms-fill-upper,
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:focus::-ms-fill-upper {
  background: rgba(0,0,0, 0.35);
}

.dwcc-cookieconsent.dwcc-theme-zeiss button:focus,
.dwcc-cookieconsent.dwcc-theme-zeiss a:focus,
.dwcc-cookieconsent.dwcc-theme-zeiss input[type=range].dwcc-rangeslider:focus {
  outline: 1px dotted rgba(0,0,0, 0.5);
}


.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies button:focus,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies a:focus {
  outline: 1px dotted rgba(127,127,127, 0.75);

}



.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-rangeslider-category::before,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-rangeslider-category::after {
  background-color: rgba(0,0,0, 0.25);
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close {
  border-radius: 3px;
  border: 0 none;
  background-color: transparent;
  outline-offset: 1px;
  width: 1.5em;
  height: 1.5em;
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close::after,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close::before {
  background-color: #0072ef;
  border-bottom-color: #0052AA;
  border-top-color: #0052AA;
}
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-box_cookies .dwcc-close{
  /* background-color: transparent; */
}

.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close:focus,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close:hover {
  background-color: #efefef;
  border: 0 none;
}


/* .dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close:focus::after,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close:focus::before,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close:hover::after,
.dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-close:hover::before {
  background-color: #fff;
  border-bottom-color: #fff;
  border-top-color: #fff;
} */


.dwcc-restricted_3rdparty-content-hidden {
  display: none !important;
}
.dwcc-restricted_3rdparty-content {
    max-width: 600px;
    margin: 2em auto 4em auto;
}
.dwcc-restricted_3rdparty-content > div {
  margin: 0 2em;
}
.dwcc-restricted_3rdparty-content h4 {
  font-size: 1.3em;
}
.dwcc-restricted_3rdparty-content p {
  font-size: 1.1em;
}
.map .dwcc-restricted_3rdparty-content h4 {
  font-size: 1.2em;
}
.dwcc-restricted_3rdparty-content-hidden {
  display: none !important;
}


@media screen and (max-width: 800px) {
  .dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-inner .dwcc-disclaimer-text h1 {
    font-size: 18px !important;
    font-weight: 600;
  }

  .dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button-secondary, .dwcc-cookieconsent.dwcc-theme-zeiss .dwcc-button {
    font-size: 14px;
    padding: 0.75em 1.5em;
  }
}